import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import { Helmet } from "react-helmet";
import PostLoop from "../components/PostLoop";
import Seo from "../components/Seo";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { useTranslation } from "gatsby-plugin-react-i18next";
import CookieConsent from "react-cookie-consent";

const Tag = ({ data, location }) => {
  const tag = data.tagsJson;
  const posts = data.allMarkdownRemark.nodes;
  const totalPosts = data.allMarkdownRemark.totalCount;

  // Extract the slug from the location object
  const slug = location.pathname.split('/').pop();

  // Define a function to generate the post URL based on the language
  const getPostUrl = (lang, slug) => {
    return lang === 'ar' ? `https://www.quranplayermp3.com/ar/tag/${slug}` : `https://www.quranplayermp3.com/tag/${slug}`;
  };

  const getPathWithoutLangPrefix = (pathname) => {
    return pathname.replace(/^\/ar\//, '/');
  };

  const { t } = useTranslation();

  return (
    <Layout>
      <Helmet>
        {/* English version with reciprocal link */}
        <link rel="alternate" hrefLang="en" href={`https://www.quranplayermp3.com/tag${tag.fields.slug}`} />
        <link rel="alternate" hrefLang="ar" href={`https://www.quranplayermp3.com/ar/tag${tag.fields.slug}`} />
        <link rel="alternate" hrefLang="fr" href={`https://www.quranplayermp3.com/fr/tag${tag.fields.slug}`} />
        <link rel="alternate" hrefLang="es" href={`https://www.quranplayermp3.com/es/tag${tag.fields.slug}`} />
        <link rel="alternate" hrefLang="de" href={`https://www.quranplayermp3.com/de/tag${tag.fields.slug}`} />
        <link rel="alternate" hrefLang="tr" href={`https://www.quranplayermp3.com/tr/tag${tag.fields.slug}`} />
        <link rel="alternate" hrefLang="ru" href={`https://www.quranplayermp3.com/ru/tag${tag.fields.slug}`} />
        <link rel="alternate" hrefLang="zh" href={`https://www.quranplayermp3.com/zh/tag${tag.fields.slug}`} />
        <link rel="alternate" hrefLang="ur" href={`https://www.quranplayermp3.com/ur/tag${tag.fields.slug}`} />
        <link rel="alternate" hrefLang="pt" href={`https://www.quranplayermp3.com/pt/tag${tag.fields.slug}`} />
        <link rel="alternate" hrefLang="it" href={`https://www.quranplayermp3.com/it/tag${tag.fields.slug}`} />
        <link rel="alternate" hrefLang="id" href={`https://www.quranplayermp3.com/id/tag${tag.fields.slug}`} />
        <link rel="alternate" hrefLang="bn" href={`https://www.quranplayermp3.com/bn/tag${tag.fields.slug}`} />
        <link rel="alternate" hrefLang="nl" href={`https://www.quranplayermp3.com/nl/tag${tag.fields.slug}`} />
        <link rel="alternate" hrefLang="hi" href={`https://www.quranplayermp3.com/hi/tag${tag.fields.slug}`} />
        <link rel="alternate" hrefLang="fa" href={`https://www.quranplayermp3.com/fa/tag${tag.fields.slug}`} />
        <link rel="alternate" hrefLang="ug" href={`https://www.quranplayermp3.com/ug/tag${tag.fields.slug}`} />
        <link rel="alternate" hrefLang="so" href={`https://www.quranplayermp3.com/so/tag${tag.fields.slug}`} />
        <link rel="alternate" hrefLang="sw" href={`https://www.quranplayermp3.com/sw/tag${tag.fields.slug}`} />
        <link rel="alternate" hrefLang="no" href={`https://www.quranplayermp3.com/no/tag${tag.fields.slug}`} />
        <link rel="alternate" hrefLang="da" href={`https://www.quranplayermp3.com/da/tag${tag.fields.slug}`} />
        <link rel="alternate" hrefLang="se" href={`https://www.quranplayermp3.com/se/tag${tag.fields.slug}`} />
        <link rel="alternate" hrefLang="ha" href={`https://www.quranplayermp3.com/ha/tag${tag.fields.slug}`} />
        <link rel="alternate" hrefLang="bs" href={`https://www.quranplayermp3.com/bs/tag${tag.fields.slug}`} />
        <link rel="alternate" hrefLang="th" href={`https://www.quranplayermp3.com/th/tag${tag.fields.slug}`} />
        <link rel="alternate" hrefLang="tg" href={`https://www.quranplayermp3.com/tg/tag${tag.fields.slug}`} />
        <link rel="alternate" hrefLang="tl" href={`https://www.quranplayermp3.com/tl/tag${tag.fields.slug}`} />
        <link rel="alternate" hrefLang="x-default" href={`https://www.quranplayermp3.com/tag${tag.fields.slug}`} />
      </Helmet>
      <Seo
        title={tag.name}
        description={tag.description}
        isTagPage={true}
      />
      <div className="main">
        <div className="container">
          <div className="archive-cover">
            <div className={`archive-cover-inner cover-tag flex${tag.coverImage !== null ? " has-image" : ""}`}>
              {tag.coverImage && (
                <GatsbyImage
                  className="cover-image"
                  image={getImage(tag.coverImage)}
                  alt={t(tag.name)}
                />
              )}
              <div className="cover-content-wrapper">
                <div className="tag-info-wrap text-center">
                  <h1 className="tag-name h2">{t(tag.name)}</h1>
                  <div className="archive-info">
                    <span className="post-count">{totalPosts > 1 ? `${totalPosts} ${t("posts")}` : `${totalPosts} ${t("post")}`}</span>
                  </div>
                  <div className="tag-description">{t(tag.description)}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <PostLoop posts={posts} />
      </div>
      <CookieConsent
        location="bottom"
        buttonText={t("Accept Cookies")}
        cookieName="myAwesomeCookieName2"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150}
      >
        {t("This website uses cookies to enhance the user experience.")}
      </CookieConsent>
    </Layout>
  );
};

export default Tag;

export const query = graphql`
  query tagQuery($slug: String!, $language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["common", "index"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    tagsJson(fields: { slug: { eq: $slug } }) {
      color
      name
      description
      coverImage {
        childImageSharp {
          gatsbyImageData(
            width: 1200
            placeholder: BLURRED
            formats: [AUTO, WEBP]
            transformOptions: { fit: COVER }
          )
        }
      }
      fields {
        slug
      }
    }
    allMarkdownRemark(
      sort: { fields: frontmatter___date, order: DESC }
      filter: {
        frontmatter: {
          tags: { elemMatch: { fields: { slug: { eq: $slug } } } }
          published: { ne: false }
        }
      }
    ) {
      totalCount
      nodes {
        id
        fields {
          slug
        }
        frontmatter {
          title
          date
          dateFormatted: date(formatString: "MMMM DD, YYYY")
          featuredImage {
            childImageSharp {
              gatsbyImageData(
                width: 340
                placeholder: BLURRED
                formats: [AUTO, WEBP]
                transformOptions: { fit: COVER }
              )
            }
          }
          description
          tags {
            fields {
              slug
            }
            color
            name
            description
          }
          featured
        }
        excerpt(pruneLength: 200)
      }
    }
  }
`
